//import Lead from '@/store/Models/Lead'
//import MyLeadAPI from '@/store/API/MyLead'
import QFilters from '@/components/utils/QFilters.vue'
const { Parser } = require('json2csv');

export default {
    //override this.
    props: {
        'title': {
            type: String,
            default: 'Data Table'
        },
        'rows': {
            type: Number,
            default: 5
        },
        'moreLink': {
            type: String,
            default: null
        }
    },
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead', //override this...
            selected: [],
            dialog: false,
            sync_dialog: false,
            search: null,
            selectAll: false,
            currentItems: [],
            filters: {},
            showFilters: false,
            options: {},
			allowGoTo: true,
        }
    },
    computed: {
        filter_keys: function() {
            //ovveride this.
            //return Lead.state().filter_keys;
        },
        all_data: function() {
            //override this
            //return Lead.all()
        },
        the_headers: function() {
            //override this to return your headers.
            return [{
                    text: 'Name',
                    value: 'name_address',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    divider: true,
                    class: 'header-class',
                },
                {
                    text: 'Disposition',
                    value: 'Disposition',
                    sortable: true,
                    divider: true,
                    filterable: true
                }, {
                    text: 'Email',
                    value: 'Email',
                    sortable: true,
                    filterable: true,
                    width: 85
                }, {
                    text: 'County',
                    value: 'County',
                    sortable: true,
                    filterable: true
                }, {
                    text: 'Type',
                    value: 'Type',
                    sortable: true,
                    filterable: true,
                    bottom_filter: true,
                }, {
                    text: 'Lead Level',
                    value: 'LeadLevel',
                    divider: true,
                    sortable: true,
                    filterable: true
                }, {
                    text: 'Workflow',
                    value: '$id',
                    sortable: false,
                    filterable: false
                }
            ]
        },
        loading: function() {
            return this.$store.state.entities[this.entity].loading;
        },
        total_items: function() {
            return this.$store.state.entities[this.entity].total;
        },
        available_filters: function() {
            return this.$store.state.entities[this.entity].availableFilters;
        },
        computed_rows: {
            get() {
                return this.rows
            },
            set(val) {
                this.$emit('update:rows', val)
            }
        },
		entityId () {
			return this.entity + '_datatable'
		},
    },
    methods: {
        updateCurrentItems: function(items) {
            this.currentItems = items;
        },
        toggleFilters: function() {
            this.showFilters = !this.showFilters
        },
        loadData: function(opt) {
            //override this to load your data
            //MyLeadAPI.getMyLeads(this.filters, opt);
        },
        refreshData: function(refreshFilters = false) {
            var extra = {
                needAvailableFilters: !!refreshFilters || this.available_filters === null
            }
            var opt = {
                ...this.options,
                search: this.search,
                ...extra
            }
            this.loadData(opt);
        },
        maybeSearch: function(event) {
            if (event.keyCode === 13) {
                this.refreshData();
            }
        },
        formatPhoneNumber: function(phoneNumberString) {
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3]
            }
            return ""
        },
        startDownload: function(json, fields) {
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(json);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify(this.title + " " + this.endDate);
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
            } catch (err) {
                console.error(err);
            }
        },
        clicked: function(href, newTab = false) {
            if (href === undefined || href === null) {
                return;
            }

            if (href.startsWith('http') || href.startsWith('https')) {
                if (href.indexOf('quility.com') > -1 && !newTab) {
                    document.location = href;
                } else {
                    window.open(href, '_tab');
                }
            } else {
                this.$router.push(href);
            }
        },
    },
    watch: {
        'selectAll': function(newV) {
            if (newV) {
                this.selected = this.currentItems;
            } else {
                this.selected = [];
            }
        },
        'showFilters': function(newV) {
            this.$refs.filters.show = newV
        },
        'search': function(newV) {
            this.options.page = 1
        },
        'filters': {
            deep: true,
            handler(newV) {
                var g = this;
                this.options.page = 1
                this.$nextTick(g.refreshData)
            }
        },
        'options': {
            deep: true,
            handler(newV) {
                var g = this;
                if (!this.firstPull && this.allowGoTo) {
                    this.$vuetify.goTo('#' + this.entityId, {
                        duration: 250,
                        easing: 'easeOutCubic',
                    });
                }
                this.$nextTick(g.refreshData)
            }
        },
    },
    components: {
        QFilters
    }
}
