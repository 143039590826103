<template>
    <v-container>
        <v-row justify="center" v-if="!hideButton">
            <v-col cols="12" class="text-center">
                <v-btn @click="show = !show">Filters</v-btn>
            </v-col>
        </v-row>

        <v-row v-if="show && availableFilters" :dense="!$props.column" :justify="alignment">
			<slot name="before" />

            <v-col v-for="(filter, key) in filtersWithSelect" :key="key" v-bind="colsAttrs">
                <v-switch
                    v-if="key.indexOf('_switch') > -1"
                    v-bind="getFiltersDetails(key)"
                    :label="formatLabel(key)"
                    v-model="filters[key]"
                />
                <v-select
                    v-else
                    v-bind="getFiltersDetails(key)"
                    :label="formatLabel(key)"
                    :items="options[key]"
                    v-model="filters[key]"
                    :multiple="typeof filters[key] == 'object' && filters[key]['disableMultiple'] !== true"
                    :clearable="typeof filters[key] == 'object' && filters[key]['disableMultiple'] !== true"
                />
            </v-col>

            <v-col
                v-if="checkboxFilterKeys.length > 0"
                v-bind="colsAttrs"
                class="d-flex justify-center"
            >
                <v-menu :close-on-content-click="false" max-height="55vh">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mt-4 text-wrap"
                            :color="someCheckboxFiltersSelected ? 'primary' : 'rgba(0, 0, 0, 0.4)'"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            block
                            style="width: 100%;"
                        >
                            Other filters
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="key  in checkboxFilterKeys" :key="key">
                            <QIndeterminateCheckbox 
                                v-model="filters[key]" 
                                :label="formatLabel(key)" 
                                :nullValue="options[key][0]"
                                :trueValue="options[key][1]"
                                :falseValue="options[key][2]"
                            />
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>

            <v-col v-if="hasDateRangeFilter" v-bind="colsAttrs">
                <q-date-range-picker :value="date_range" v-on:update="updateDateRange" :light="true"></q-date-range-picker>
            </v-col>

            <v-col v-if="showReset" v-bind="colsAttrs">
                <v-btn @click="resetFilters" class="mt-4">Reset to Default Filters</v-btn>
            </v-col>
            <slot></slot>
        </v-row>
    </v-container>
</template>

<script>
import QIndeterminateCheckbox from '../controls/QIndeterminateCheckbox.vue'
import QDateRangePicker from './QDateRangePicker.vue';

export default {
    name: "QFilters",
    components: { QIndeterminateCheckbox, QDateRangePicker },
    props: {
        keys: {},
        data: {},
        value: {},
        hideButton: { type: Boolean, default: false },
        availableFilters: {},
        /** Filter names that should be hidden in dropdown */
        checkboxesFilters: { type: Array, default: () => [] },
        showReset: { type: Boolean, default: () => false },
        alignment: { type: String, default: 'start' },
        filtersDetails: { type: Array, default: () => [] },
		column: { type: Boolean, default: false },
    },
    data: function() { // data internal to component (not available outside)
        return {
            show: false,
            filters: {},
            showFilters: false,
            defaultFilters: {},
            date_range: [null, null],
        }
    },
    mounted: function() {
        this.defaultFilters = this.value;
        this.resetFilters()
    },
    computed: {
        options: function() {
            if (this.availableFilters) {
                return this.availableFilters;
            }
            return this.keys.reduce(((acc, key) => {
                acc[key] = this.getOptions(key)
            }, {}));
        },
        filtersWithSelect() {
            const copy = {}

            Object
                .keys(this.filters)
                .filter((key) => !this.checkboxesFilters.includes(key) && key != 'DateRange')
                .forEach((key) => { copy[key] = this.filters[key]; })

            return copy
        },
        checkboxFilterKeys() {
            return Object
                .keys(this.filters)
                .filter((key) => this.checkboxesFilters.includes(key))
                .sort()
        },
        someCheckboxFiltersSelected() {
            return this.checkboxFilterKeys.some((key) => this.filters[key] !== '-All-')
        },
        hasDateRangeFilter(){
            return ('DateRange' in this.filters)
        },
		colsAttrs () {
            return this.column  
                ? { cols: 12 }
                : { cols: 12, xs: 6, sm: 3, md: 2 }
        },
    },
    methods: {
        updateFilters: function() {
            this.$emit('input', this.filters)
        },
        getOptions: function(key) {
            if (this.data) {
                var opt = ['-All-']
                for (var i = 0; i < this.data.length; i++) {
                    if (opt.indexOf(this.data[i][key]) == -1) {
                        opt.push(this.data[i][key]);
                    }
                }
                return opt.sort();
            }
            return [];
        },
        resetFilters: function(defaultFilters = {}) {
            for (var i = 0; i < this.keys.length; i++) {
                const key = this.keys[i];
                this.setOptionDefaultValue(key, defaultFilters[key]);
            }
        },
        setOptionDefaultValue(key, value) {
			if (value !== undefined) {
                this.$set(this.filters, key, value);
			} else if (key === 'DateRange'){
                this.$set(this.filters, key, []);
            } else if (key === 'Carrier' && this.filterByCarrierID > 0) {
                this.$set(this.filters, key, this.filterByCarrierID);
            } else if (this.defaultFilters[key]) {
                this.$set(this.filters, key, this.defaultFilters[key]);
            } else {
                this.$set(this.filters, key, '-All-');
            }
        },
        formatLabel: function(key) {
            return key.replace("_id", "")
                .replace("_switch", "")
                .replace(/^Is([A-Z]+)/g, "$1")
                .replaceAll("_", " ")
                .replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1")
                .replace(' Ind', '')
        },
        updateDateRange: function(v) {
            this.filters.DateRange = {startDate: v[0], endDate: v[1]};
        },
        getFiltersDetails(filterKey) {
            const filter = this.filtersDetails.find(({ key }) => key === filterKey)
            return filter?.details ?? undefined;
        },
		hide () {
			this.show = false
		},
    },
    watch: {
        'show': function(v) {
            if (!v) {
                this.resetFilters();
            }
        },
        'filters': function(f) {
            this.$emit('input', f)
        },
        $route(to, from) {
            if (to.params && to.params.carrier_id > 0) {
                this.defaultFilters.Carrier = to.params.carrier_id
                this.filterByCarrierID = to.params.carrier_id
                this.resetFilters()
            }
        },
        keys(newValue, oldValue) {
            const newKeys = newValue.filter(key => !(key in this.filters))
            newKeys.forEach(key => {
                this.setOptionDefaultValue(key)
            })

            const removedKeys = oldValue.filter(key => !newValue.includes(key))
            removedKeys.forEach(key => {
                this.$delete(this.filters, key)
            })
        },
    },
}
</script>
